.home_container_1 {
    margin-top: 136px;
    width: 100%;
    position: relative;
}

.home_container_1 img {
    width: 100%;
}

.home_container_1 .text_block {
    position: absolute;
    top: 20%;
    left: 13%;
    max-width: 591px;
    width: 100%;
}

.home_container_1 .text_block p {
    text-transform: uppercase;
    color: #FFD51E;
    font-size: 24px;
    line-height: 36px;
}

.home_container_1 .text_block h1 {
    font-size: 90px;
    line-height: 120%; /* 108px */
    letter-spacing: 2.7px;
    margin-top: 20px;
}

.home_container_1 .text_block .text {
    font-size: 20px;
    line-height: 180%; /* 36px */
    letter-spacing: 0.6px;
    margin-top: 40px;
    color: rgba(255, 255, 255, 0.80);
}

.take_quiz_b {
    max-width: 200px;
    width: 100%;
    margin-top: 40px;
}

.take_quiz_btn {
    width: 100%;
    padding: 12px 32px;
    border-radius: 8px;
    background: #004AAD;
    color: white;
}

.take_quiz_btn img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.info_b {
    padding: 50px 0;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 0px 41px 0px rgba(36, 40, 57, 0.15);
}

.info_b div p {
    color: rgba(10, 24, 56, 0.60);
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-top: 24px;
}
@media(max-width: 1650px){
    .home_container_1 .text_block {
        top: 10%;
        left: 7%;
    }
    .home_container_1 .text_block .text {
        margin-top: 20px;
    }
    .take_quiz_b {
        margin-top: 20px;
    }
}
@media(max-width: 1320px){
    .home_container_1 .text_block h1 {
        font-size: 70px;
        line-height: 100%;
    }
    .home_container_1 .text_block {
        max-width: 551px;
        width: 100%;
    }
}
@media(max-width: 1150px){
    .home_container_1 .text_block h1 {
        font-size: 50px;
        line-height: 70%;
    }
    .home_container_1 .text_block {
        max-width: 501px;
        width: 100%;
        left: 2%;
    }
    .home_container_1 .text_block .text {
        font-size: 20px;
        line-height: 25px;
        margin-top: 30px;
    }
}
@media(max-width: 900px) {
    .home_container_1 .text_block {
       max-width: 350px;
    }
    .home_container_1 .text_block h1 {
        font-size: 40px;
        line-height: 100%;
    }
    .home_container_1 .text_block .text {
       display: none;
    }
    .take_quiz_b {
        margin-top: 30px;
    }
}