.accordion {
    max-width: 744px;
    width: 100%;
    margin: 32px auto 0px;
}

.accordion_block {
    padding: 16px;
    border-radius: 10px;
    border: 1px solid  #0A183819;
}
.accordion_title{
    color:  #0A1838;
    line-height: 180% !important;
    letter-spacing: 0.36px;
    cursor: pointer;
}
.accordion_block_active {
    padding: 16px;
    border-radius: 10px;
    border: 1px solid  #0A183819;
}
.tabs_block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabs_block button {
    padding: 0px 32px 12px;
}
.tabs_block button:first-child {
    margin-right: 8px;
}

.showTab {
    display: block;
}

.hideTab {
    display: none;
}
.accordion_description{
    color:  #0A1838;
    font-size: 16px;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
}