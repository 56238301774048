.sign_container {
    width: 100%;
}

.sign_container_b1 {
    width: calc(50%);
}

.sign_container_b1_inner {
    max-width: 445px;
    width: 100%;
}

.sign_text {
    color: rgba(10, 24, 56, 0.80);
    font-size: 16px;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
}

.inputs_block {
    margin-top: 40px;
}

.input_block label {
    color: #0A1838;
    margin-bottom: 12px;
}

.input_block input {
    border-radius: 8px;
    border: 1px solid rgba(10, 24, 56, 0.20);
    padding: 16px;
}

.password_b {
    position: relative;
}

.password_b input {
    padding-right: 40px !important;
}

.show_hidden_icon {
    position: absolute;
    top: 62%;
    right: 3%;
    cursor: pointer;
}

.forgot_pass {
    cursor: pointer;
    color: rgba(10, 24, 56, 0.80);
}
.forgot_pass:hover {
   font-weight: 700;
}

.sign_container  .sign_btn  button {
    border-radius: 8px;
    background: #004AAD;
    width: 100%;
    margin-top: 60px;
    padding: 12px 32px;
}
.have_account{
    margin-top: 60px;
    color: rgba(10, 24, 56, 0.80);
}
.have_account span{
    text-decoration: underline;
    color: #004AAD;
    cursor: pointer;
}
.have_account span:hover{
   font-weight: 700;
}


.sign_container_b2 {
    width: calc(50%);
}

.sign_container_b2 img {
    width: 100%;
    height: 100%;
}


