@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf');
}

body{
  background: #F7F9FF;
}
* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  box-sizing: border-box;
  color: #333;
  border: none;
  outline: none;
  font-weight: 400;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
}
button:hover {
  transform: scale(1.03);
}

.container {
  max-width: 1432px;
  padding: 0px 16px;
  width: 100%;
  margin: 0 auto;
}


.d_grid {
  display: grid;
  align-items: center;
  justify-items: center;
}
.grid_columns_4fr {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid_columns_3fr {
  grid-template-columns: 1fr 1fr 1fr;
}
.grid_columns_2fr {
  grid-template-columns: 1fr 1fr;
}

.grid_gab {
  grid-gap: 50px;
}
.grid_gab_32{
  grid-gap: 32px;
}
.grid_gab_24{
  grid-gap: 24px;
}

.d_flex {
  display: flex;
}

.fd_column {
  flex-direction: column;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_end {
  justify-content: flex-end;
}

.justify_content_start {
  justify-content: start;
}

.space_between {
  justify-content: space-between;
}

.align_items_center {
  align-items: center;
}

.align_items_start {
  align-items: flex-start;
}

.align_items_end {
  align-items: flex-end;
}

.f_400 {
  font-weight: 400;
}

.f_500 {
  font-weight: 500;
}

.f_600 {
  font-weight: 600;
}

.f_700 {
  font-weight: 700;
}
.fs_8 {
  font-size: 8px;
  line-height: 12px;
}
.fs_10 {
  font-size: 10px;
  line-height: 15px;
}
.fs_12 {
  font-size: 12px;
  line-height: 18px;
}

.fs_14 {
  font-size: 14px;
  line-height: 20px;
}

.fs_16 {
  font-size: 16px;
  line-height: normal;
}

.fs_18 {
  font-size: 18px;
  line-height: normal;
}
.fs_20{
  font-size: 20px;
  line-height:normal;
}
.fs_24{
  font-size: 24px;
  line-height: normal;
}
.fs_40 {
  font-size: 40px;
  line-height: 52px;
}
.margin_top_8{
  margin-top: 8px;
}
.margin_top_12{
  margin-top: 12px;
}
.margin_top_16{
  margin-top: 16px;
}
.margin_top_24{
  margin-top: 24px;
}
.margin_top_32{
  margin-top: 32px;
}
.margin_top_48{
  margin-top: 48px;
}
.margin_top_160{
  margin-top: 160px;
}
.c_white {
  color: #FFFFFF;
}
.c-red {
  color: red;
}
.c_darkBlue{
 color: #0A1838;
}
.bc_darkBlue{
  background: #0A1838;
}
.c_blue{
  color: #004AAD;
}
.bc_blue{
  background: #004AAD;
}
input{
  background: none;
}
input:focus {
  outline: none;
}

.text_center {
  text-align: center;
}
.text_left {
  text-align: left;
}


.flex_wrap{
  flex-wrap: wrap;
}

.error {
  color: red;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: -20px;
  text-align: center;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}




.scroll_top_icon {
  position: fixed;
  right: 1%;
  bottom: 2%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
