.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root, .css-1xr9krm {
    font-size: 1rem !important;
    line-height:1.5rem !important;
    letter-spacing: 0.01071em;
    font-weight: 500 !important;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected, .css-1xr9krm.Mui-selected{
    background-color: #004AAD !important;
    color: white !important;
    padding: 12px !important;
    font-weight: 600 !important;
}
.pagination_cont{
    margin-top: 80px;
}
.css-0{
    max-width: unset !important;
}