.listing_search_cont {
    margin-top: 160px;
}

.listing_search_cont_search_b {
    max-width: 1224px;
    width: 100%;
}

.listing_search_cont_search_b div {
    max-width: 1064px;
    width: 100%;
}

.listing_search_cont_search_b div img {
    position: absolute;
    top: 20%;
    left: 1%;
}

.listing_search_cont_search_b input {
    width: 100%;
    border-radius: 8px;
    border: 2px solid rgba(0, 74, 173, 0.20);
    background: #FFF;
    padding: 13px 12px 13px 40px;
}

.listing_search_cont_search_b button {
    max-width: 160px;
    width: 100%;
    padding: 12px 32px;
    margin-left: -7px;
    z-index: 1;
    border-radius: 8px;
}
.listing_search_cont_save_search_b{
    margin-left: 16px;
    max-width: 160px;
    width: 100%;
}
.listing_search_cont_save_search_b button{
    width: 100%;
    border-radius: 8px;
    border: 1px solid  #00285F;
    padding: 11px;
    color: #0A1838;
    white-space: nowrap;
}