.flat_desc_cont {
    margin-top: 180px;
}
.flat_desc_cont .title{
    font-size: 44px;
    font-weight: 600;
    line-height: 150%; /* 66px */
    letter-spacing: 0.88px;
    color: #0A1838;
}
.flat_desc_cont .title_address{
    color: #0A1838;
    font-weight: 400;
    line-height: 150% !important; /* 27px */
    letter-spacing: 0.36px;
}
.flat_desc_cont .title_address img{
   margin-right: 8px;
}

.flat_desc_cont_b1 {
    max-width: 860px;
    width: 100%;
}

.flat_desc_cont_b1 h2 {
    font-size: 44px;
    font-weight: 600;
    line-height: 150%; /* 66px */
    letter-spacing: 0.88px;
    color: #0A1838;
}

.flat_desc_cont_b1 .desc {
    color: #0A1838;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.36px;
}

.flat_desc_cont_b1 .desc img {
    margin-right: 8px;
}

.flat_desc_cont_b1_content{
    max-width: 700px;
    width: 100%;
}
.highlights_b, .description_b, .details_b{
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(10, 24, 56, 0.10);
    margin-bottom: 40px;
}

.flat_desc_cont_b1_content h2 {
    color: #0A1838;
    font-size: 24px;
    font-weight: 600;
    line-height: 180%; /* 43.2px */
    letter-spacing: 0.48px;
}

.highlights_b_1 div, .highlights_b_2 div{
    display: flex;
    align-items: center;
}
.highlights_b_1 div img, .highlights_b_2 div img{
  margin-right: 9px;
    width: 24px;
    height: 24px;
    font-weight: 500;
    color: #0A1838;
}
 .highlights_b_2{
  margin-left: 100px;
}
 .details_b{

 }
