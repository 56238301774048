.categories_container{

}
.categories_container h2{
    color: #0A1838;
    font-size: 40px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
}
.title_2{
    max-width: 650px;
    width: 100%;
    color: rgba(10, 24, 56, 0.80);
    text-align: center;
    font-size: 16px;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
    margin: 16px auto 0;

}
.categories_cont {
    margin: 60px auto;
    max-width: 1042px;
    width: 100%;
}

.categories_cont_item {
    position: relative;
    max-width: 326px;
    width: 100%;
    border-radius: 7px;
}
.categories_cont_item:hover {
  transform: scale(1.02);
}
.categories_cont_item img{
    border-radius: 7px;
    width: 100%;
}

.categories_cont_item p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.48px;
    white-space: nowrap;
}
.view_all_btn_b{
    margin-top: 60px;

}
.view_all_btn{
    border-radius: 8px;
    border: 1px solid  #00285F;
    color: #00285F;
    padding: 12px 32px;
}