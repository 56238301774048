.flat_desc_cont_b2 {
    max-width: 480px;
    width: 100%;
    background: #00285F;
    padding: 32px;
    height: max-content;
}

.flat_desc_cont_b2 .price_title {
    color: rgba(255, 255, 255, 0.60);
    letter-spacing: 2px;
    text-transform: uppercase;
}

.flat_desc_cont_b2 .price {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 600;
    line-height: 39px; /* 130% */
}

.flat_desc_cont_b2_content {
    border-top: 1px solid rgba(255, 255, 255, 0.20);
}

.flat_desc_cont_b2_content_input_b {
    position: relative;
    width: 100%;

}

.flat_desc_cont_b2_content_input_b input, .flat_desc_cont_b2_content_input_b textarea {
    padding: 17px 17px 17px 40px;
    border-radius: 8px;
    border: 1px solid rgba(247, 249, 255, 0.40);
    width: 100%;
    color: rgba(255, 255, 255, 0.60);
    background: transparent;
}

.flat_desc_cont_b2_content_input_b input::placeholder {
    color: rgba(255, 255, 255, 0.60);
}

.flat_desc_cont_b2_content_input_b img {
    position: absolute;
    top: 26%;
    left: 2%;
}

.flat_desc_cont_b2_content_input_b .message_icon {
    position: absolute;
    top: 12% !important;
    left: 2%;
}

.flat_desc_cont_b2_content button {
    width: 100%;
    padding: 12px 32px;
    border-radius: 8px;
}

.contact_agent_pic {
    width: 72px;
    height: 72px;
    border-radius: 50%;
}

.CA_info_b span {
    color: rgba(255, 255, 255, 0.60);
    margin-top: 6px;
}