.search_filter_cont{
    max-width: 329px;
    width: 100%;
}
.reset_filters_btn{
    color:  rgba(10, 24, 56, 0.60);
    font-size: 16px;
    font-weight: 500;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
    margin-left: 16px;
}
.search_filter_cont_content{
    margin-top: 8px;
    border-top: 1px solid rgba(10, 24, 56, 0.10);
    padding-top: 24px;
}
.down_icon{
    cursor: pointer;
}
.top_icon{
    cursor: pointer;
    transform: rotate(180deg);
}
.home_type_b{
    cursor: pointer;
}
.dropdown_content{
margin-top: 16px;
}

.dropdown_content_item{
    color: #0A1838;
    font-size: 16px;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
    margin-bottom: 12px;
}
.dropdown_content_item input[type="checkbox"] {
    accent-color: #004AAD;
    margin-right: 12px;
    width: 22px;
    height: 22px;
    border-radius: 10px;
    cursor: pointer;
}
.add_margin{
    margin-top: 80px;
}