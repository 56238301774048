.header_login_container {
    padding: 32px 0px;
    position: fixed;
    top: 0px;
    margin: auto;
    width: 100%;
    z-index: 99;
    background: white;
}

.logo {
    cursor: pointer;
    max-width: 100px;
    width: 100%;
    margin-right: 44px;
}

.header_btn {
    text-decoration: none;
    margin-right: 34px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 18px;
    line-height: 23px;
}

.header_btn:hover, .header_btn_active:hover {
    transform: scale(1.03);
    color: #00285F;
}

.header_btn_active {
    text-decoration: none;
    margin-right: 34px;
    cursor: pointer;
    color: #00285F;
    white-space: nowrap;
    font-size: 20px;
    line-height: 30px;
}

.sign_up_btn button {
    max-width: 133px;
    width: 100%;
    padding: 12px 32px;
    border-radius: 8px;
    background: #004AAD;
    color: white;
    white-space: nowrap;
}

.sign_in_btn {
    color: #00285F;
    padding: 12px 32px;
    width: 100%;
    white-space: nowrap;
}

.user_login_cont {
    position: relative;
    cursor: pointer;
}

.user_login_cont .user_pic {
    width: 32px;
    height: 32px;
}

.user_login_cont p {
    margin: 0px 8px;
}

.header_dropdown {
    position: absolute;
    top: 50px;
    right: 12%;
    width: 280px;
    padding: 16px;
    z-index: 999;
    border-radius: 8px;
    border: 1px solid rgba(10, 24, 56, 0.10);
    background: #FFF;
}

.dropdown_item {
    color: #0A1838;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 143.75% */
    padding: 12px;
}

.dropdown_item:hover {
    font-weight: 600;
}
.dropdown_item img{
    margin-right: 8px;
}
.exit_btn_b{
    border-top: 1px solid rgba(10, 24, 56, 0.10);
    padding: 16px 12px 12px 12px;
}
.exit_btn_b button{
    color:  #E23636;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px; /* 143.75% */
    margin-left: 8px;
}
.exit_btn_b button:hover{
    font-weight: 600;
}