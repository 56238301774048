.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}
.mySwiperLarge {
    height: 80%;
    width: 100%;
}

.mySwiperSmall {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiperSmall .swiper-slide {
    width: 25%;
    height: max-content;
    opacity: 0.4;
    cursor: pointer;
}

.mySwiperSmall .swiper-slide-thumb-active {
    opacity: 1;
}

.mySwiperSmall .swiper-slide img{
    display: block;
    width: 100%;
    height: 72px;
    object-fit: cover;
}



