.how_works_container {

}

.how_works_container h2 {
    color: #0A1838;
    font-size: 40px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
}

.title_2 {
    max-width: 650px;
    width: 100%;
    color: rgba(10, 24, 56, 0.80);
    text-align: center;
    font-size: 16px;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
    margin: 16px auto 0;
}

.how_works_container_content {
    margin: 80px auto 0;
   width: 100%;
}

.how_works_container_content_item {
    max-width: 287px;
    width: 100%;
}
.arrow_b{
   margin:  auto 16px;;
}
.arrow_b img:hover{
    transform: scale(1.05);
}

.how_works_container_content_item img{
    max-width: 130px;
    width: 100%;
}
.how_works_container_content_item p{
    color:  #0A1838;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    max-width: 205px;
    width: 100%;
}
.how_works_container_content_item div{
    color: rgba(10, 24, 56, 0.90);
    text-align: center;
    font-size: 16px;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
}