.footer_container {
    width: 100%;
    padding: 81px 0px 31px;
    background: #00285F;
}

.footer_b_1 .logo {
    max-width: 160px;
    width: 100%;
    margin-bottom: 40px;
}

.social_blocks {
    margin-top: 20px;
}

.social_blocks div {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.social_blocks img:hover {
    transform: scale(1.05);
}

.footer_b_2 {
    max-width: 966px;
    width: 100%;
}

.footer_b_2_item {
    margin-right: 16px;
    max-width: 320px;
    width: 100%;
}

.footer_b_2_item:last-child {
    margin-right: 0px;
}

.footer_b_2_item h3 {
    margin-bottom: 8px;
}

.footer_btn, .footer_btn_active {
    text-align: left;
    color: rgba(255, 255, 255, 0.80);
    text-decoration: none;
    font-size: 16px;
    line-height: normal;
    margin-top: 16px;
}

.footer_btn:hover, .footer_btn_active:hover {
    color: white;
    transform: scale(1.01);
}

.footer_btn_active {
    color: white;
    font-weight: 700;
}

.email_block {
    margin-top: 16px;
    border-radius: 8px;
    background: #FFF;
    padding: 4px 4px 4px 16px;
    margin-bottom: 40px;
    width: 100%;
}

.email_block input {
    max-width: 200px;
    width: 100%;
    color: #001A30;
    font-size: 14px;
    line-height: normal;
}
.email_block input::placeholder {
    color: #001A30;
}

.email_block button {
    padding: 8px 16px;
    color: #FFF;
    background: #004AAD;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;

}

.tel_icon_b {
    margin-top: 16px;
}

.tel_icon_b img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.tel_icon_b a {
    color: rgba(255, 255, 255, 0.80);
    font-size: 16px;
    line-height: normal;
    text-decoration: none;
}

.rights_b {
    margin-top: 100px;
    border-top: 1px solid #F7F9FF1A;
    padding-top: 24px;
    opacity: 0.6;
}