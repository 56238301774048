.why_choose_container {
    width: 100%;
}

.why_choose_b2 {
    max-width: 578px;
    width: 100%;
    margin-left: 24px;
}

.why_choose_b2 h2 {
    color: #0A1838;
    font-size: 40px;
    font-weight: 600;
    line-height: 148%; /* 59.2px */
    letter-spacing: 0.8px;
    margin-bottom: 40px;
}

.why_choose_b2 div {
    color: rgba(10, 24, 56, 0.80);
    font-size: 16px;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
}
.view_listings_btn_b {
  margin-top: 40px;

}
.view_listings_btn {
    padding: 12px 32px;
    border-radius: 8px;
    background: #004AAD;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}
.why_choose_b{
    max-width: 726px;
    width: 100%;
}
.why_choose_b1_item{
    padding: 32px;
    max-width: 351px;
    width: 100%;
    border-radius: 16px;
    background: white;
    min-height: 302px;
}
.why_choose_b1_item:first-child, .why_choose_b1_item:last-child{
    background: #E5F0FB !important;
}
.why_choose_b1_item .number_circle{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: white;
    font-size: 24px;
    font-weight: 500;
    line-height: 180%;
}
.why_choose_b1_item .desc{
    color:  rgba(10, 24, 56, 0.80);
    font-size: 14px;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
    letter-spacing: 0.28px;
    opacity: 0.8;
}