.related_properties_cont{
    margin: 160px 0px 160px;
}
.related_properties_cont h2{
    color: #0A1838;
    font-size: 40px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
}
.related_properties_cont p{
    max-width: 650px;
    width: 100%;
    margin: auto;
    color:  rgba(10, 24, 56, 0.80);
    font-size: 16px;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.related_properties_content{
    margin-top: 60px;
    width: 100%;
}