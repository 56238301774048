.range_slider {
    margin-top: 32px;
    width: 100% !important;
}

.css-0 {
    max-width: 312px !important;
    width: 100% !important;
}

.css-14pt78w-MuiSlider-rail, .css-b04pc9{
    border-radius: 6px !important;
    background: #0A183833 !important;
    height: 6px !important;
}

.css-eg0mwd-MuiSlider-thumb::after, .css-7drnjp::after{
    height: 24px !important;
    width: 24px !important;
    background: #004AAD !important;
}

.css-1gv0vcd-MuiSlider-track, .css-1t2bqnt{
    background: #004AAD !important;
    border: none !important;
    height: 6px !important;
}

.css-nnid7-MuiSlider-valueLabel, .css-3besu {
    background: #FFF !important;
    filter: drop-shadow(0px 0px 41px rgba(36, 40, 57, 0.15)) !important;
    width: 110px !important;
    height: 50px !important;
    border-radius: 6px !important;
    top: 200% !important;
    transform: unset !important;
}


.css-nnid7-MuiSlider-valueLabel:before, .css-3besu::before {
    position: absolute;
    content: "";
    top: -28% !important;
    left: 42% !important;
    background-color: unset !important;
    width: 0 !important;
    height: 0 !important;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 14px solid white !important;
    -webkit-transform: unset !important;
    -moz-transform:unset !important;
    -ms-transform: unset !important;
    transform: unset !important;
}
.MuiSlider-valueLabelLabel{
    color:  #0A183899 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

