.explore_container h2 {
    color: #0A1838;
    font-size: 40px;
    font-weight: 600;
    line-height: 148%; /* 59.2px */
    letter-spacing: 0.8px;
}

.explore_container .desc {
    color: rgba(10, 24, 56, 0.80);
    font-size: 16px;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
    max-width: 601px;
    width: 100%;
    opacity: 0.8;
}

.explore_swiper_container {
    margin-top: 60px;
    position: relative;
    z-index: 10;
}

/*.swiper {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}



.explore_swiper_container .swiper {
    position: unset !important;
    margin-left: auto;
    margin-right: auto;
}

.explorer_swiper_slide {
    border-radius: 8px;
    max-width: 326px;
    width: 100%;
    position: relative;
}

.explorer_swiper_slide img {
    border-radius: 8px;
    width: 100%;
}
.flat_pic{
    width: 100%;
}

.explorer_swiper_slide_info_b {
    padding: 24px 16px;
    background: white;

}

.explorer_swiper_slide_info_b .location_b img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.explorer_swiper_slide_info_b .location_b {
    white-space: nowrap;
    color: #0A1838;
    font-size: 16px;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
}

.explorer_swiper_slide_info_b1 h3 {
    color: #0A1838;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-right: 16px;

}

.explorer_swiper_slide_info_b1 span {
    color: #0A1838;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
}

.flat_info_b {
    padding-top: 24px;
    border-top: 1px solid rgba(10, 24, 56, 0.10);
}

.flat_info_b div {
    margin-right: 16px;
    color: #0A1838;
    font-size: 14px;
    line-height: 150%; /* 21px */
    letter-spacing: 0.28px;
}

.flat_info_b div:last-child {
    margin-right: 0px;

}

.flat_info_b div img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: url("../../../../assets/images/icons/next slide.svg") !important;
}
.swiper-button-next {
    position: absolute;
    top: -20% !important;
    right: 2% !important;
    margin-top: unset !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: url("../../../../assets/images/icons/previous slide.svg") !important;
}
.swiper-button-prev{
    position: absolute;
    top: -20% !important;
    left: 92% !important;
    margin-top: unset !important;
    z-index: 10;

}

@media(max-width: 1420px){
    .swiper-button-prev{
        left: 90% !important;
    }
}

@media(max-width: 1024px){
    .explorer_swiper_slide_info_b .location_b {
        white-space: unset;
    }
    .swiper-button-prev{
        left: 88% !important;
    }
}
@media(max-width: 900px){
    .explore_swiper_container {
        margin-top: 30px;
    }
    .swiper-button-prev, .swiper-button-next{
       top: -14% !important;
    }
    .swiper-button-prev{
        left: 85% !important;
    }
}
@media(max-width: 650px){
    .swiper-button-prev{
        left: 82% !important;
    }
}