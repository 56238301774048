.quiz_modal {
    inset: 50% auto auto 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
    overflow: auto;
    outline: none;
    max-width: 1146px;
    width: 100%;
    flex-shrink: 0;
    z-index: 999;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
}
.ReactModal__Overlay--after-open{
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.600) !important;
}

.close_modal {
    font-size: 20px;
    height: 10px;
    position: absolute;
    top: 1%;
    right: 1.5%;
    color: #0A183899;
}
.close_modal:hover {
  font-weight: 700;
}

.modal_content {
    /*padding: 80px;*/
    margin: auto;
    padding: 80px;
    width: 100%;
}

.quiz_modal_block {
    width: 100%;
}

.quiz_modal_block1 {
    border-right: 1px solid rgba(10, 24, 56, 0.10);
    max-width: 275px;
    width: 100%;
}
.quiz_modal_block1  .line{
    background:  #004AAD;
    width: 2px;
    height: 24px;
    margin-left: 23px;
}
.quiz_modal_block1_item {
    color: #0A1838;
    font-size: 16px;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
}

.quiz_modal_block1_item span {
    border-radius: 32px;
    background: rgba(10, 24, 56, 0.10);
    width: 48px;
    height: 48px;
    color: rgba(10, 24, 56, 0.60);
    margin-right: 16px;
}
.quiz_modal_block1_item  .active {
 background: #004AAD !important;
    color: white !important;
}

.quiz_modal_block2 {
    margin-left: 32px;
    max-width: 397px;
    width: 100%;
}

.quiz_modal_block2 h2 {
    white-space: nowrap;
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
    color: #0A1838;
}
.quiz_modal_block2 .info{
    color: var(--Dark-80, rgba(10, 24, 56, 0.80));
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
    margin-top: 8px;
}

.quiz_modal_block2_items {
   margin-top: 16px;
}
.quiz_modal_block2_item{
    color: #0A1838;
    font-size: 20px;
    font-weight: 400;
    line-height: 180%; /* 36px */
    letter-spacing: 0.4px;
}
.quiz_modal_block2_item input[type="checkbox"] {
    accent-color: #004AAD;
    margin-right: 12px;
    width: 22px;
    height: 22px;
    border-radius: 10px;
    cursor: pointer;
}
.next_b{
    margin-top: 48px;
}
.next_b .previous_b{
    margin-right: 12px;
    cursor: pointer;
}
.next_b button{
    padding: 12px 32px;
    color:  #FFF;
    border-radius: 6px;
}
.next_b button span{
    color:  #FFF;
    margin-left: 12px;
    height:24px;
    font-weight: 600;
}
.information_input_b{
    max-width: 350px;
    width: 100%;
}
.information_input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(10, 24, 56, 0.20);
    padding: 12px 16px;
    color:  rgba(10, 24, 56, 0.60);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}
.information_input:first-child{
    margin-top: 0px;
}
.ranger_input_b{
    max-width: 320px;
    width: 100%;
}
.ranger_input_b div{
    max-width: 152px;
    width: 100%;
}
.ranger_input_b input{
    border-radius: 8px;
    border: 1px solid rgba(10, 24, 56, 0.20);
    width: 100%;
    padding: 12px 16px;
    color:  rgba(10, 24, 56, 0.60);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.ranger_input_b div:first-child{
    margin-right: 16px;
}
