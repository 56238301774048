.search_results_cont {
    margin-left: 32px;
    max-width: 1042px;
    width: 100%;
}

.sort_b p {
    color: rgba(10, 24, 56, 0.60);
    font-size: 18px;
    font-weight: 500;
    line-height: 180%; /* 32.4px */
    letter-spacing: 0.36px;
}

.sort_b div {
    color: #0A1838;
    font-size: 16px;
    font-weight: 500;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.32px;
    cursor: pointer;
}

.sort_b div img {
    padding: 0px 10px;
}

.search_results_content {
    max-width: 1042px;
    width: 100%;
}
.search_results_content_item{
    max-width: 326px;
    width: 100%;
    cursor: pointer;
}
.search_results_content_item .flat_pic{
    width: 100%;
}
.search_results_content_info_b{
    padding: 24px 16px;
    background: white;
    width: 100%;
    margin-top: -5px;
}

.search_results_content_info_b.location_b img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.search_results_content_info_b .location_b {
    white-space: nowrap;
    color: #0A1838;
    font-size: 16px;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
}

.search_results_content_info_b1 h3 {
    color: #0A1838;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
    margin-right: 16px;

}

.search_results_content_info_b1 span {
    color: #0A1838;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
}
.flat_info_b {
    padding-top: 24px;
    border-top: 1px solid rgba(10, 24, 56, 0.10);
}

.flat_info_b div {
    margin-right: 16px;
    color: #0A1838;
    font-size: 14px;
    line-height: 150%; /* 21px */
    letter-spacing: 0.28px;
}

.flat_info_b div:last-child {
    margin-right: 0px;

}

.flat_info_b div img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}